import React, { useState } from "react";
import { Table } from 'react-bootstrap'
import UploadFile from './UploadFile'
import ErrorBanner from "./ErrorBanner";

/*
* Component makes a table of files which is generated by user input. The input is passed through props and contains 
 1. Function - enableNext, disableNext (these functions determine whether the NEXT button is active or disabled )
 2. transaction_id
 3. array of files to be displayed in rows
* 
*/
function UploadTable(props) {
    const [error, setError] = useState("")

    return (
        <>
            <ErrorBanner error={error} isIdr={props.isIdr}/>
            <div style={{ paddingTop: "20px" }}>
                <Table hover size="sm">
                    <thead>
                        <tr>
                            <th>File</th>
                            <th>Select File [Max file size: 200 MB]</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.files.map((file, index) =>
                            <UploadFile setError={setError} enableNext={props.enableNext} index={index} key={index} transaction_id={props.transactionId} business_filetype={file.schemaName} human_business_filetype={file.businessFriendlyName} mandatory={file.mandatoryFlag} disableNext={props.disableNext} />
                        )}
                    </tbody>
                </Table>
            </div>
            <p style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}>
                * Required
            </p>
        </>
    )
}

export default UploadTable
