function Header(props) {
    return (
        <div>
            <header ng-class="{'full-screen-header': isFullScreen}" className="header">
                <div className="header-primary">
                    <div className="wrapper edges" role="navigation">
                        <div className="">
                            <div className="nav-left pull-left">
                                <div className="header-logo">
                                    <a className="brandmark" analytics-event="ASIC.GOV" analytics-category="Navigation" analytics-label="Logo" href="http://asic.gov.au/" target="_blank" rel="noopener noreferrer">
                                        <span className="sr-only">Australian Securities and Investments Commission homepage</span>
                                        <img aria-label="ASIC Logo" src="/asic-logo-xs.svg" alt="ASIC" height="56px" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="protected">
                <div className="header-secondary">
                    <div className="wrapper" role="navigation" ng-hide="isASICPortal">
                        <nav>
                            <ul className="nav navbar-nav">
                                <li ng-if="!!user && (!user.SSO || user.SSOWithLogout) && !preview && isOnline">
                                    <a className="logout-button" href="#/logout" analytics-event="Logout" analytics-category="Authentication" analytics-label="Menu">
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="header-content">
                    <div className="header-title">
                        <div className="wrapper edges">
                            <div className="row">
                                <div className="col-xs-12">
                                <h2 className="weight-regular" ng-bind="formTitle"><b>{props.message}</b></h2>
                                    {/* <h2 className="weight-regular" ng-bind="formTitle"><b>File Upload - {window.ENV}</b></h2> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header