import React, { useEffect, useRef } from "react";
import "./App.css";
import Header from "./components/Header";
import Error from "./components/Error"
import axios from "axios";
import Footer from "./components/Footer";
import UploadTable from "./components/UploadTable";
import ErrorUnauthenticated from "./components/ErrorUnauthenticated";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import CONFIG from "./config/config.json"

function App() {
  const title = "File Upload"
  const [statusCode, setStatusCode] = React.useState("loadingState");
  const [disabled, setDisabled] = React.useState(true);
  const [displayData, setDisplayData] = React.useState([]);
  const listofFiles = useRef([])
  const listofFiles_mandatoryFlag = useRef([])

  const disableNext = () => {
    setDisabled(true);
  };

  const enableNext = (fileStatus, index, mandatoryFlagString, fileName, schemaName, fileType, fileSize) => {

    // Hold a list of all the "validation success" files, with null placeholders for files which are not "validation success"
    if (fileStatus === 'Validation success') {
      listofFiles.current[index] = {
        schemaName: schemaName,
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
        validationStatus: fileStatus
      }
    } else {
      listofFiles.current[index] = null
    }
    //console.log(listofFiles.current)

    // Check to make sure every mandatory file is "validation success" before enabling next button
    listofFiles_mandatoryFlag.current[index] = { mandatoryFlagString, fileStatus }
    //console.log(listofFiles_mandatoryFlag.current)
    setDisabled(!checkMandatoryFilesValid(listofFiles_mandatoryFlag.current))
  }

  const checkMandatoryFilesValid = (array) => {
    for (const value of array) {
      if (value.mandatoryFlagString === "Y") {
        if (value.fileStatus !== "Validation success") {
          //console.log("at least one mandatory file not valid")
          return false
        }
      }
    }
    //console.log("all mandatory file valid")
    return true
  }

  useEffect(() => {
    const setTransactions = async (payloadData) => {
      let body = payloadData;
      const id_token = sessionStorage.getItem('id_token');
      await axios.post(window.API_REST + "/upload/transaction", body, { headers: { 'Authorization': id_token } })
        .then(resp => {
          console.log("REST /transactions")
          setStatusCode("apiSuccess")
        }
        ).catch(error => {
          console.log(error)
          setStatusCode("apiError")
        }
        )
    };

    const getPayload = async () => {
      let id_token = sessionStorage.getItem('id_token');
      let payloadid = sessionStorage.getItem('payloadid');
      await axios.get(window.API_REST + "/upload/getpayload?payloadid=" + payloadid, { headers: { 'Authorization': id_token } })
        .then(resp => {
          setDisplayData(resp.data)
          setTransactions(resp.data)
          console.log(resp.data)
        })
        .catch(error => {
          console.log(error)
          setStatusCode("authenticationError")
        }
        )
    };

    //todo - to make sure getpayload won't render the page again
    if (window.location.pathname === "/") {
      getPayload();
    }

    console.log(displayData);
  }, []);

  const add_prefix = (targeturl) => {
    return targeturl.startsWith(window.PREFIX) ? targeturl : window.PREFIX + targeturl;
  }
  const onSuccessRedirect = (applicationId, listofFiles) => {
    sessionStorage.clear();
    let f = document.createElement('form');
    // we need to add back http or https if missing
    const originalSuccessRedirectUrl = displayData.successRedirectUrl;

    f.action = add_prefix(originalSuccessRedirectUrl);
    f.method = 'POST';

    // remove null objects from listofFiles 
    var listofFiles_filtered = listofFiles.filter(function (el) {
      return el !==null;
    });
    //console.log(listofFiles_filtered);
    
    f.appendChild(getInput("applicationId", applicationId))
    f.appendChild(getInput("listofFiles", listofFiles_filtered))

    document.body.appendChild(f);
    f.submit();
  }

  const getInput = (name, value) => {
    let k = document.createElement('input');
    k.type = 'hidden';
    k.name = name;
    k.value = typeof (value) === 'string' ? value : JSON.stringify(value);

    return k
  }

  const onCancelledRedirect = () => {
    var originalcancelledRedirectUrl = `https://${window.ENV === "prd" ? "" : window.ENV}${CONFIG.CANCEL_URL}`
    if (displayData.cancelledRedirectUrl) {
      originalcancelledRedirectUrl = displayData.cancelledRedirectUrl;
    }
    console.log(displayData.cancelledRedirectUrl)
    sessionStorage.clear();
    window.location = add_prefix(originalcancelledRedirectUrl)
  }
  //wrapper table form-content
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Header message={title} />
          <div className="wrapper table form-content" style={{ padding: "15px" }}>

            <div style={{ paddingBottom: "20px" }}>
              {(() => {
                switch (statusCode) {
                  case "authenticationError":
                    return (
                      <ErrorUnauthenticated />
                    )
                  case "loadingState":
                    return (
                      <div className="text-center">
                        <div className="spinner-border text-primary" />
                      </div>
                    )
                  case "apiError":
                    return (
                      <></>
                      //<GenereicError section={error}/>
                    )
                  case "apiSuccess":
                    if (displayData.dataCollectionName === 'DX_IDR'){
                      return (
                        <div className="form-content">
                          <div>
                            <div className="asic-tooltips-warning">
                              <i className="fa fa-exclamation-circle pull-left pt-1 px-2 text-warning fs-2"></i>
                              <p className="ps-5">
                              Before you upload your file, ensure you review <strong>Appendix 3: Submission checklist</strong> from the IDR data reporting handbook (which you can download from ASIC's '<a href="https://asic.gov.au/regulatory-resources/financial-services/dispute-resolution/internal-dispute-resolution-data-reporting/" target="_blank">Internal dispute resolution data reporting</a>' webpage).
                              The submission checklist summarises what is required for your data file to successfully pass both stages of ASIC's validation process (both basic validation and deep validation). 
                              Your firm's IDR data reporting requirement will only be met once your data file has successfully passed <strong>both</strong> stages of data validation. 
                              If your data file fails either stage of data validation or you need to submit an updated file, you will need to resubmit your file.
                              </p>
                            </div>
                            <p>
                              Click on the 'Upload' button to open a file browser window from your desktop. From here you can select the relevant CSV file to upload.
                            </p>
                            <p>
                              If you are able to progress past this screen, it means that your data file has passed <strong>basic validation</strong>, but it will not yet have undergone <strong>deep validation</strong>.
                              We will notify you by email within 48 hours as to whether your file has passed or failed deep validation.
                            </p>
                          </div>
                          <UploadTable
                            enableNext={enableNext}
                            disableNext={disableNext}
                            files={displayData.listofFiles}
                            transactionId={displayData.transactionId}
                            isIdr={true}
                          />
                        </div>
                      )
                    }
                    else {
                      return (
                        <div className="form-content">
                          <UploadTable
                            enableNext={enableNext}
                            disableNext={disableNext}
                            files={displayData.listofFiles}
                            transactionId={displayData.transactionId}
                            isIdr={false}
                          />
                        </div>
                      )
                    }
                  default:
                    return null;
                }
              })()}
            </div>

            <div
              className="form-navigation clearfix section-border-top"
              style={{ display: "flex" }}>

              <button
                type="button"
                className="btn btn-default mobile-btns mobile-save-close-btn"
                style={{ marginLeft: "auto" }}
                onClick={() => { onCancelledRedirect() }}>
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-iapply mobile-btns mobile-save-close-btn"
                disabled={disabled}
                onClick={() => { onSuccessRedirect(displayData.applicationId, listofFiles.current) }}>
                <i><FaArrowCircleRight /></i>
                Next
              </button>
            </div>
          </div>
          <Footer />
        </Route>
        <Route path="/error" render={({ }) => {
          const location_search = window.location.search;
          const errormsg = new URLSearchParams(location_search).has("errormsg") ? new URLSearchParams(location_search).get("errormsg") : null;
          return <Error errormsg={errormsg} />;
        }}
        />
        <Route path="/auth" render={() => {
          window.location.href = window.API_COGNITO + "oauth2/authorize?client_id=" + window.COGNITO_CLIENT_ID + "&response_type=token&scope=email+openid&redirect_uri=" + window.COGNITO_CALLBACK_URL;
        }} />
        <Route path="/storepayloadid" render={() => {
          const location_search = window.location.search;
          const save_to_session_storage = (key) => {
            const value = new URLSearchParams(location_search).has(key) ? new URLSearchParams(location_search).get(key) : null;
            sessionStorage.setItem(key, value);
          }
          save_to_session_storage('payloadid');
          window.location.href = window.API_COGNITO + "logout?client_id=" + window.COGNITO_CLIENT_ID + "&logout_uri=" + window.COGNITO_LOGOUT_URL
        }} />
        <Route path="/callback" render={() => {
          const location_hash = window.location.hash;
          const save_to_session_storage = (key) => {
            const value = new URLSearchParams(location_hash).has(key) ? new URLSearchParams(location_hash).get(key) : null;
            sessionStorage.setItem(key, value);
          }
          save_to_session_storage('id_token');
          window.location.href = "/"
        }} />
        <Route path="/download" render={() => {
          const location_search = window.location.search;
          const save_to_session_storage = (key) => {
            const value = new URLSearchParams(location_search).has(key) ? new URLSearchParams(location_search).get(key) : null;
            sessionStorage.setItem(key, value);
          }
          save_to_session_storage('applicationId');
          save_to_session_storage('entityId');
          window.location.href = window.API_COGNITO + "logout?client_id=" + window.COGNITO_CLIENT_ID + "&logout_uri=https://" + window.location.hostname + "/downloadcallback";
        }} />
        <Route path="/downloadcallback" render={() => {
          let applicationId = sessionStorage.getItem('applicationId');
          let entityId = sessionStorage.getItem('entityId');
          sessionStorage.clear();
          window.location.assign(`${window.API_REST}/download/download/?applicationId=${applicationId}&entityId=${entityId}`);
          return (
            <div>
              <Header message={"Downloading file. Once download is complete you can close this tab, review the file and resubmit your data report."} />
              <Footer />
            </div>
          )
        }} />

      </Switch>
    </Router>
  );
}

export default App;