function ErrorBanner(props) {
  const allowedChars = "` ~ ! @ # $ % ^ & * ( ) + = [ { } ] | \ : ; \" ' < , . & ? /";
  switch (props.error) {

    case 'Fail':
      if (props.isIdr){
        return (
          <div className="error-summary">
            Error: An issue with machine-readable headers in the file has been detected. Ensure that:
            <ul>
              <li>the first row of the data file sets out the machine-readable headers exactly as stated in the IDR data reporting handbook (which can be downloaded from ASIC's '<a href="https://asic.gov.au/regulatory-resources/financial-services/dispute-resolution/internal-dispute-resolution-data-reporting/" target="_blank">Internal dispute resolution data reporting</a>' webpage),</li>
              <li>the data file is saved with "CSV UTF-8 (Comma delimited) (*.csv)" option in Microsoft Excel,</li>
              <li>the headers are in the correct order,</li>
              <li>the file only contains 22 columns,</li>
              <li>the column headers are spelled correctly and written with no spaces, and </li>
              <li>there are no blank columns or duplicate columns.</li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="error-summary">
            Error: We detected a fault in the uploaded file's table structure. <br></br>
            You will need to reformat the file and upload it again before you can proceed.
          </div>
        );
      }
    case 'Size':
      return (
        <div className="error-summary">
          Error: An issue with the file size has been detected. Ensure that the data file is not larger than 200 MB. <br></br>
        </div>
      );

    case 'Malware':
      return (
        <div className="error-summary">
          Error: An issue with file integrity has been detected. Ensure that the data file is free of viruses and/or malware before uploading.<br></br>
        </div>
      );

    case 'EmptyFile':
      return (
        <div className="error-summary">
          Error: An issue with data file has been detected. Ensure that the data file contains at least one row of data.<br></br>
          Note: If there are no complaints in the relevant reporting period, you are required to lodge a 'nil submission' to ASIC, rather than a data file.
        </div>
      );

    case 'Format':
      return (
        <div className="error-summary">
          Error: An issue with the file type has been detected. Ensure that the data file is a comma separated values (CSV) file. <br></br>
        </div>
      );

    case 'SpecialCharacter':
      return (
        <div className="error-summary">
          Error: An issue with the file name has been detected. Ensure that the data file name does not contain any of these special characters: 
                      {allowedChars} <br></br>
          Note: A full stop in the file name extension (in this case, the '.csv') is acceptable. 
        </div>
      );

    case 'Internal_Error':
      return (
        <div className="error-summary">
          Error: An internal system error has occurred. Please try again later, and if the problem persists, please contact support. <br></br>
        </div>
      );

    default:
      return (<div></div>);
  }
}

export default ErrorBanner;