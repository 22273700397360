import CONFIG from "../config/config.json"
import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";

function DisplayIcon(props) {

  switch (props.status) {

    case CONFIG.STATUS.VALIDATION_PASSED:
      return (
        <>
          <FaCheckCircle
            style={{
              paddingLeft: 5,
              height: 25,
              width: 25,
              color: "green"
            }}
          />
          <p style={{ marginLeft: '10px', display: "inline" }}>Successfully Uploaded</p>
        </>
      )

    case CONFIG.STATUS.VALIDATION_FAILED:
      return (
        <>
          <FaExclamationCircle
            style={{
              paddingLeft: 5,
              height: 25,
              width: 25,
              color: "orange"
            }}
          />
          <p style={{ marginLeft: '10px', display: "inline" }}>Schema Validation Failed</p>
        </>
      )

    case CONFIG.STATUS.VALIDATION_FAILED_EMPTY_FILE:
      return (
        <>
          <FaExclamationCircle
            style={{
              paddingLeft: 5,
              height: 25,
              width: 25,
              color: "orange"
            }}
          />
          <p style={{ marginLeft: '10px', display: "inline" }}>Empty file</p>
        </>
      )

    case CONFIG.STATUS.MALWARE_FAILED:
      return (
        <>
          <FaExclamationCircle
            style={{
              paddingLeft: 5,
              height: 25,
              width: 25,
              color: "orange"
            }}
          />
          <p style={{ marginLeft: '10px', display: "inline" }}>Malware Detected</p>
        </>
      )
  
    case CONFIG.STATUS.INTERNAL_SERVER_ERROR:
      return (
        <>
          <FaExclamationCircle
            style={{
              paddingLeft: 5,
              height: 25,
              width: 25,
              color: "orange"
            }}
          />
          <p style={{ marginLeft: '10px', display: "inline" }}>Internal Server Error</p>
        </>
      )

    case CONFIG.STATUS.INTERNAL_SERVER_ERROR_SCHEMA:
      return (
        <>
          <FaExclamationCircle
            style={{
              paddingLeft: 5,
              height: 25,
              width: 25,
              color: "orange"
            }}
          />
          <p style={{ marginLeft: '10px', display: "inline" }}>Internal Server Error</p>
        </>
      )

    case CONFIG.STATUS.RECEIVED:
      return (
        <>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p style={{ marginLeft: '10px', display: "inline" }}>Processing File</p>
        </>
      )

    case CONFIG.STATUS.NOT_RECEIVED:
      return (
        <p style={{ marginLeft: '10px', display: "inline" }}>Awaiting Upload</p>
      )

    default:
      return (
        <p style={{ marginLeft: '10px', display: "inline" }}>{props.status}</p>
      )
  }
}

export default DisplayIcon
