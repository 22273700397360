function Footer() {
    return (
        <footer>
            <div className="container container-asic">
                <div className="row margin-top-xs-7">
                    <div className="col-sm-4 footer-logo">
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <nav>
                                <ul>
                                    <li className="col-sm-4">
                                        <a href="http://www.asic.gov.au/about-asic/contact-us/" target="_blank" rel="noopener noreferrer">Contact us</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="https://regulatoryportal.asic.gov.au/faq/" target="_blank" rel="noopener noreferrer">Frequently asked questions</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="https://regulatoryportal.asic.gov.au/privacy/">Privacy</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="https://regulatoryportal.asic.gov.au/about-the-portal/" target="_blank" rel="noopener noreferrer">About the portal</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="https://regulatoryportal.asic.gov.au/accessibility/">Accessibility</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="http://www.asic.gov.au/about-asic/dealing-with-asic/using-our-website/copyright-and-linking-to-our-websites/" target="_blank" rel="noopener noreferrer">Copyright</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="http://www.asic.gov.au/about-asic/freedom-of-information-foi/information-publication-scheme/" target="_blank" rel="noopener noreferrer">Information publication scheme</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="https://regulatoryportal.asic.gov.au/password-management/">Password management</a>
                                    </li>
                                    <li className="col-sm-4">
                                        <a href="https://regulatoryportal.asic.gov.au/sitemap/" target="_blank" rel="noopener noreferrer">Sitemap</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="copyright">Copyright &copy; 2021 Australian Securities &amp; Investments Commission</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer