import { React, useState, useEffect, useRef } from "react";
import axios from "axios";
import CONFIG from "../config/config.json";
import { FaPlus } from "react-icons/fa";
import DisplayIcon from "./DisplayIcon";

function FileUpload(props) {
  const [status, setStatus] = useState("");
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const fileName = useRef();
  const fileType = useRef();
  const fileSize = useRef();
  const timer = useRef();

  useEffect(() => {
    wsconnect();
  }, []);
  
  const specialCharacterRegex = (fileName) => {
    const regex = /[`~!@#$%^&()+={[}\];'<>,.]/; // regex to identify any special characters `~!@#$%^&()+={[}];'<>,.
    fileName = fileName.split('.').slice(0, -1).join('.')
    return regex.test(fileName); // returns boolean of whether fileName contains special characters
  }

  const handleFileInput = (e) => {
    if (e.target.files.length === 1) {
      if (e.target.files[0].name.split(".").pop() !== "csv") {
        props.setError("Format");
      } else if (e.target.files[0].size > CONFIG.MAX_FILE_SIZE) {
        props.setError("Size");
      } else if (specialCharacterRegex(e.target.files[0].name)) {
        props.setError("SpecialCharacter");
      } else {
        if (props.mandatory.toUpperCase() === "Y") {
          props.disableNext();
        }
        setUploadDisabled(true);
        fileName.current = e.target.files[0].name;
        fileType.current = e.target.files[0].name.split(".").pop();
        fileSize.current = e.target.files[0].size;
        getPresignedUrl(e.target.files[0]);
        props.setError();

        // Re-establishes web-socket connection if there has been no activity for CONFIG.TIMEOUT period of time      
         timer.current = setTimeout(() => {
          console.log("WS Connection Timed-Out - " + props.business_filetype)
          wsconnect()
          console.log("WS Connection Reconnecting - " + props.business_filetype)
          setUploadDisabled(false);
        }, CONFIG.TIMEOUT)
      }
    }
  };

  const getPresignedUrl = async (file) => {
    let body = {
      transaction_id: props.transaction_id.toString(),
      business_filetype: props.business_filetype,
      filename: file.name,
      content_type: file.type,
    };
    let id_token = sessionStorage.getItem("id_token");
    const resp = await axios
      .post(window.API_REST + "/upload/s3url", body, {
        headers: { Authorization: id_token },
      })
      .then(console.log("REST /s3url - " + body.filename));
    uploadFile(file, resp.data);
  };

  const enableNext = (
    result,
    index,
    mandatory_flag,
    fileName,
    business_filetype,
    fileType,
    fileSize
  ) => {
    // Here, we invoke the callback with the new value
    props.enableNext(
      result,
      index,
      mandatory_flag,
      fileName,
      business_filetype,
      fileType,
      fileSize
    );
  };

  let ws;

  const wsconnect = () => {
    let queryString =
      window.API_WEBSOCKET +
      "?transaction-id=" +
      props.transaction_id +
      "&business-filetype=" +
      props.business_filetype;
    ws = new WebSocket(queryString);

    ws.onopen = (event) => {
      props.setError();
      console.log("WS Connection Established - " + props.business_filetype);
    };
    ws.onclose = (event) => {
      console.log("WS Connection Closed - " + props.business_filetype);
      setTimeout(wsconnect, 1000)
    };
    ws.onerror = (error) => {
      console.log("WS Connection Error - " + props.business_filetype + " with error: " + JSON.stringify(error));
      ws.onclose()
    };
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.result) {
        setStatus(data.result);
        //mandatoryFlagString.toUpperCase() === "Y"
        enableNext(
          data.result,
          props.index,
          props.mandatory,
          fileName.current,
          props.business_filetype,
          fileType.current,
          fileSize.current
        );
        console.log("WS MSG: " + data.result + " - " + props.business_filetype);
        if (data.result === CONFIG.STATUS.NOT_RECEIVED) {
          setStatus(CONFIG.STATUS.NOT_RECEIVED);
        }
        if (data.result === CONFIG.STATUS.VALIDATION_FAILED) {
          props.setError("Fail");
        }
        if (data.result === CONFIG.STATUS.VALIDATION_FAILED_EMPTY_FILE){
          props.setError("EmptyFile");
        }
        if (data.result === CONFIG.STATUS.MALWARE_FAILED) {
          props.setError("Malware");
        }
        if (data.result === CONFIG.STATUS.INTERNAL_SERVER_ERROR) {
          props.setError("Internal_Error");
        }
        if (data.result === CONFIG.STATUS.INTERNAL_SERVER_ERROR_SCHEMA) {
          props.setError("Internal_Error");
        }
        if (data.result === CONFIG.STATUS.RECEIVED) {
          // Processing state, nothing to do here...
        }
        if (data.result !== CONFIG.STATUS.RECEIVED) {
          setUploadDisabled(false)
          clearTimeout(timer.current)
        }
      }
    };
  };

  const uploadFile = async (file, upload) => {
    await axios
      .put(upload, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-server-side-encryption": "aws:kms",
        },
      })
      .then(console.log("REST S3 Upload - " + props.business_filetype));
  };

  return (
    <tr>
      <td>
        {props.human_business_filetype}{" "}
        {props.mandatory.toUpperCase() === "Y" ? (
          <p style={{ color: "red", display: "inline" }}> * </p>
        ) : (
          <p style={{ color: "grey", display: "inline" }}>(Optional)</p>
        )}
      </td>
      <td>
        <>
          <label className="btn btn-iapply" disabled={uploadDisabled}>
            <FaPlus style={{ paddingRight: 5, height: 20, width: 20 }} /> Upload
            <input
              type="file"
              // Change accept criteria here, if in future you need to upload different kinds of files.
              accept=".csv"
              disabled={uploadDisabled}
              onChange={handleFileInput}
              hidden
            ></input>
          </label>
          <div
            style={{
              display: "inline",
              marginLeft: "10px",
              wordWrap: "break-word",
            }}
          >
            {fileName.current}
          </div>
        </>
      </td>
      <td>
        <div className="row">
          <div className="col">
            <DisplayIcon status={status} />
          </div>
        </div>
      </td>
    </tr>
  );
}

export default FileUpload;
