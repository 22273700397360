import Header from "./Header"
import Footer from "./Footer"
function Error(props) {
    const title = "Error: " + props.errormsg
    return (
        <div>
        <Header message={title} />
        <Footer />           
        </div>
    )
}

export default Error